
import { defineComponent, inject, ref } from 'vue'
import { api } from '@/api/useAxios'
import { useRouter } from 'vue-router'
import { getUserId } from '@/utils/auth'
export default defineComponent({
  props: {
    avatar: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: '昵称'
    },
    parentUserName: {
      type: String,
      required: false
    },
    parentUserId: {
      type: String,
      required: false
    },
    userid: {
      type: Number,
      default: -1
    },
    hasfollowing: {
      type: Boolean,
      default: false
    },
    isgood: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const selfUserId = ref('')
    selfUserId.value = getUserId() + ''
    const fromPages = ['study']
    const changeFollow = fromPages.includes(props.from)? inject<Function>('changeFollow')! : ()=> {}
    const handleAttention = (id: number) => {
      api.post('/focus/following/' + id).then(() => {
        changeFollow({ userId: props.userid})
      })
    }
    const router = useRouter()
    const toPage = (userId: number) => {
      if (router.hasRoute('otherHomePage')) {
        router.push('/mine/other/' + userId)
      }
    }

    return {
      handleAttention,
      // followTag,
      toPage,
      selfUserId
    }
  }
})
